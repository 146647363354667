<template>
  <div
    v-if="props.type == 'product-item'"
    class="placeholder">
    <div
      class="placeholder__image"
      :style="`--aspect-ratio:${ props.ratio }`">
    </div>
    <p class="placeholder__text first"></p>
    <p class="placeholder__text"></p>
    <p class="placeholder__text"></p>
  </div>
  <div
    v-else
    class="placeholder"
    :style="`--aspect-ratio:${ props.ratio }`">
    <div class="placeholder__random"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  ratio: {
    type: String,
    required: false,
  },
});
</script>